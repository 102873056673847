<template>
  <coverage-template
    :gender="gender"
    :facial-acne-coverage="facialAcneCoverage"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import CoverageTemplate from '@/modules/questionnaire/components/steps/questionnaire/pimples/coverage/CoverageTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField, field } = fieldBuilder;

const FIELDS = [requiredField('facialAcneCoverage'), field('gender')];

export default {
  name: 'Coverage',
  components: {
    CoverageTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    facialAcneCoverage() {
      this.showNextStep();
    }
  }
};
</script>
